.logo {
    height: 600px;
    width: 600px;
}
@media only screen and (max-width: 900px) {
    .logo {
        height: 400px;
        width: 400px;
        display: inline-block;
    }
}
