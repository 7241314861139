h1 {
    font-weight: bold;
    font-size: 30px;
}
a{
    font-weight: bold;
    font-size: 20px;
}
ul{
    list-style-type: none;
}